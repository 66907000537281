// Auto-generated RPC interface from /opt/whdata/installedmodules/rotarynl.20240815T135858.534Z/webdesigns/org2020/data.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("rotarynl:data");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/rotarynl.20240815T135858.534Z/include/rpc/data.whlib'

exports.getLoginStatus = /*RECORD*/function()
{
return request.invoke.apply(request,["getLoginStatus"].concat(Array.prototype.slice.call(arguments)));
}

exports.getContextData = /*RECORD*/function(/*RECORD*/ _settings)
{
return request.invoke.apply(request,["getContextData"].concat(Array.prototype.slice.call(arguments)));
}

exports.setMyStreamFilter = /*RECORD*/function(/*RECORD*/ _data, /*RECORD*/ _settings)
{
return request.invoke.apply(request,["setMyStreamFilter"].concat(Array.prototype.slice.call(arguments)));
}
